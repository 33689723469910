// ----------------------------------------------------------------------


const ROOTS = {
    AUTH: '/auth',
};
// ----------------------------------------------------------------------

export const paths = {
    // AUTH
    auth: {
        forgot_password: `${ROOTS.AUTH}/forgot-password`,
        sent: `${ROOTS.AUTH}/sent-successfully`,
        expired: `${ROOTS.AUTH}/expired`,
        reset_password: `${ROOTS.AUTH}/reset-password`,
    }
};
