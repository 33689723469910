import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// components
import { SplashScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

// JWT
import AuthClassicLayout from 'layouts/auth/classic';

const ForgotPassPage = lazy(() => import('pages/auth/forgot-password'));
const ResetPassPage = lazy(() => import('pages/auth/reset-password'));
const SentPage = lazy(() => import('pages/auth/sent-successfully'));
const ExpiredPage = lazy(() => import('pages/auth/expired'));
// KYCVIEW
// ----------------------------------------------------------------------

export const authRoutes = [
    {
        path: 'auth',
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: 'forgot-password',
                element: (
                    <AuthClassicLayout>
                        <ForgotPassPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'sent-successfully',
                element: (
                    <AuthClassicLayout>
                        <SentPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'reset-password/:id',
                element: (
                    <AuthClassicLayout>
                        <ResetPassPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'expired',
                element: (
                    <AuthClassicLayout>
                        <ExpiredPage />
                    </AuthClassicLayout>
                )
            }
        ]
    }
];
