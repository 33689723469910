import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'config-global';
//
import { authRoutes } from './auth';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <Navigate to={PATH_AFTER_LOGIN} replace />
        },
        // Auth routes
        ...authRoutes,
        // No match 404
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
